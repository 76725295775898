body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(213deg 85% 97%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-form {
  /*border: solid 1px grey;*/
  border-radius: 8px;
  padding: 48px;
  font-size: 24px;
  min-width: 300px;
  max-width: 60%;
  margin: 0px auto;
  background: hsl(218deg 50% 91%);
  box-shadow: 32px 32px 8em hsl(231deg 62% 94%);
}

.main-form-recipe {
  text-align: left;
}

.login-page-heading {
  margin-top: 10%;
  color: grey;
  text-align: center;
}

.recipe-page-heading {
  margin-top: 8px;
  color: grey;
}

.savings-td {
  text-align: left;
  padding: 4px;
}

.tbl_container {
  margin: 0 auto;
  width: 80%;
}

.chart-line-wrapper {
  width: 80%;
  margin: 0 auto;
}

.button-general {
  margin: 8px;
  min-height: 40px;
  min-width: 240px;
  font-size: 24px;
  padding: 16px;
  border-radius: 38px;
  margin-top: 60px;
}

.login-page-label {
  margin: 16px;
  min-width: 100px;
  max-width: 50%;
}

.form-group {
  margin: 16px;
}

.login-page-input {
  font-size: 24px;
  border-radius: 4px;
  padding-left: 16px;
  min-width: 100px;
  max-width: 50%;
}

.file-picker-input {
  font-size: 20px;
  border-radius: 4px;
  padding-left: 16px;

  @media screen and (min-width: 400px) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) {
    font-size: 24px;
  }
}

.recipe-page-main-btn-ctn {
  text-align: center;
}

.mouseover-primary {
  transition-duration: 0.4s;
  background-color: white !important;
  border: solid 1px #4DA565;
  color: black !important;
}

.button-logout {
  margin: 8px;
  min-height: 20px;
  min-width: 150px;
  font-size: 15px;
  padding: 8px;
  border-radius: 38px;
  margin-top: 8px;
  margin-left: 50%;
}

.mouseover-logout {
  transition-duration: 0.4s;
  background-color: white !important;
  border: solid 1px gray;
  color: black !important;
}

.mouseover-logout:hover {
  transition-duration: 0.4s;
  background-color: red !important;
  border: solid 1px red;
  color: white !important;
}

.mouseover-primary:hover {
  background-color: #4DA565 !important;
  border: solid 1px #4DA565;
  color: white !important;
}

.mouseover-secondary {
  transition-duration: 0.4s;
  background-color: white !important;
  border: solid 1px orange;
  color: black !important;
}

.mouseover-secondary:hover {
  background-color: orange !important;
  border: solid 1px orange;
  color: white !important;
}
